@use "@angular/material" as mat;

// NOTE: Theming is currently experimental and not yet publically released!

@include mat.core();

$md-archangellight: (
  50 : #e3e7ea,
  100 : #b9c4cc,
  200 : #8a9caa,
  300 : #5b7487,
  400 : #37576e,
  500 : #143954,
  600 : #12334d,
  700 : #0e2c43,
  800 : #0b243a,
  900 : #061729,
  A100 : #64a5ff,
  A200 : #3188ff,
  A400 : #006afd,
  A700 : #0060e4,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);


$primary: mat.define-palette(mat.$blue-palette);
$accent: mat.define-palette(mat.$blue-grey-palette, A200, A100, A400);

$theme: mat.define-light-theme($primary, $accent);

@include mat.all-component-themes($theme);

.cm-dark-theme {
  $dark-primary: mat.define-palette(mat.$pink-palette, 700, 500, 900);
  $dark-accent: mat.define-palette(mat.$blue-grey-palette, A200, A100, A400);
  $dark-warn: mat.define-palette(mat.$deep-orange-palette);

  $dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

  @include mat.all-component-themes($dark-theme);
}

.cm-archangel {
  $archangel-primary: mat.define-palette($md-archangellight, 500, 500, 500);
  $archangel-accent: mat.define-palette(mat.$blue-grey-palette, A200, A100, A400);

  $archangel-theme: mat.define-light-theme($archangel-primary, $archangel-accent);

  @include mat.all-component-themes($archangel-theme);
}

a {
  color: #555;
}
